var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var mna,O_,nna,Q_,R_,S_,T_,m0,qna,pna,rna,ona,s0,e0,u0,sna,g0;mna=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};O_=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Id($CLJS.hea,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
nna=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.rB,!0],null)),c=$CLJS.J.g(b,$CLJS.gea),d=$CLJS.J.g(b,$CLJS.rB);return $CLJS.y(a)?(b=$CLJS.wV(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=O_(x,$CLJS.Va(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee(O_(u,
$CLJS.Va(d)),k($CLJS.Lc(l)))}return null}},null,null)}(mna($CLJS.XA(a)))}()),$CLJS.n(c)?$CLJS.wV($CLJS.af(c,b)):b):null};$CLJS.P_=function(a){return $CLJS.fP.h($CLJS.A($CLJS.oL.h(a)))};Q_=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=Q_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Q_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.table",a);}return a};
R_=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=R_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=R_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.card",a);}return a};S_=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=S_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=S_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.metric",a);}return a};
T_=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=T_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=T_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.fields",a);}return a};$CLJS.U_=function(a){return $CLJS.vZ(a)?a:$CLJS.iE.h(a)};$CLJS.V_=function(a,b){return Q_($CLJS.U_(a),b)};$CLJS.W_=function(a,b){return T_($CLJS.U_(a),b)};$CLJS.X_=function(a,b){return R_($CLJS.U_(a),b)};$CLJS.Y_=function(a,b){return S_($CLJS.U_(a),b)};
$CLJS.Z_=function(a,b,c){if($CLJS.Xa(b)||$CLJS.xd(b)){$CLJS.n(b.__mbcache)||(b.__mbcache=$CLJS.Xe($CLJS.N));var d=b.__mbcache;if($CLJS.n(d)){var e=$CLJS.J.g($CLJS.q(d),a);if($CLJS.n(e))return e;b=c.h?c.h(b):c.call(null,b);$CLJS.Oh.v(d,$CLJS.R,a,b);return b}}return c.h?c.h(b):c.call(null,b)};
m0=function(a,b,c){var d=$CLJS.$_.j(a,b,c);return $CLJS.fl.l($CLJS.H([$CLJS.dm(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,$CLJS.mE,$CLJS.Uj],null)),function(){var e=$CLJS.a0.v(a,b,c,$CLJS.b0);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.c0,e],null):null}(),function(){var e=$CLJS.yA($CLJS.Ei,$CLJS.pB)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ei,e],null):null}(),function(){var e=$CLJS.$K.h(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.OW,$CLJS.d0.j(a,b,$CLJS.Fd(e)?$CLJS.V_(a,e):"string"===typeof e?
$CLJS.X_(a,$CLJS.GW(e)):null)],null):null}(),function(){var e=$CLJS.PK.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[e0,$CLJS.E.g(e,$CLJS.xZ),$CLJS.f0,$CLJS.E.g(e,$CLJS.HZ),g0,$CLJS.E.g(e,$CLJS.EZ),$CLJS.h0,$CLJS.E.g(e,$CLJS.GZ),ona,$CLJS.E.g(e,$CLJS.hL),pna,$CLJS.E.g(e,$CLJS.bL)],null):null}(),function(){var e=$CLJS.MZ.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.i0,e],null)}(),$CLJS.dm(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.j0,$CLJS.k0,$CLJS.l0],null))]))};
$CLJS.n0=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.l0=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.o0=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.h0=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.j0=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.p0=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);
$CLJS.i0=new $CLJS.M(null,"selected","selected",574897764);qna=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);pna=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);rna=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.q0=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);
$CLJS.r0=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);ona=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);s0=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.t0=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);e0=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);
u0=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);$CLJS.v0=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.f0=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.w0=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.k0=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);sna=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.c0=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.x0=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.b0=new $CLJS.M(null,"long","long",-171452093);$CLJS.y0=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.z0=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);g0=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.A0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.fC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.dC(l)},e,a,b,c,d)}();
$CLJS.B0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.fC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.dC(l)},e,a,b,c,d)}();
$CLJS.a0=function(){function a(f,k,l,m){var t=$CLJS.mE.h($CLJS.FE(l));if($CLJS.n(t))return t;try{return $CLJS.A0.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.gi($CLJS.hE("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([l])),$CLJS.tV(k)])),new $CLJS.h(null,2,[$CLJS.EF,f,$CLJS.zE,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.ci):e.call(null,f,k,l,$CLJS.ci)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.C0=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.FE(f));if($CLJS.n(k))return k;try{return $CLJS.B0.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.gi($CLJS.hE("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f])),$CLJS.tV(k)])),new $CLJS.h(null,3,[$CLJS.zE,f,$CLJS.EF,d,$CLJS.DL,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.A0.m(null,$CLJS.ci,function(a,b,c){a=$CLJS.IA($CLJS.Dy);$CLJS.n($CLJS.HA("metabase.lib.metadata.calculation",a))&&$CLJS.GA("metabase.lib.metadata.calculation",a,$CLJS.uW("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.Mh.l($CLJS.H([c])),rna,$CLJS.dC(c)])),null);return $CLJS.zd(c)&&$CLJS.A(c)instanceof $CLJS.M?$CLJS.gh($CLJS.A(c)):$CLJS.Mh.l($CLJS.H([c]))});
$CLJS.B0.m(null,$CLJS.ci,function(a,b,c){return nna($CLJS.CA($CLJS.a0.j(a,b,c),/[\(\)]/,""))});$CLJS.D0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.fC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.tna=function(){function a(d,e,f){return $CLJS.D0.j(d,e,$CLJS.zh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.E0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.fC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.dC(l)},e,a,b,c,d)}();
$CLJS.F0=function(){function a(d,e,f){var k=$CLJS.FE(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.nG),m=$CLJS.Ei.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Id($CLJS.rH,l):l)?$CLJS.vj:null;if($CLJS.n(l))return l;k=$CLJS.pB.h(k);if($CLJS.n(k))return k;d=$CLJS.E0.j(d,e,f);return $CLJS.rC(d,$CLJS.zj)?d:$CLJS.zj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.E0.m(null,$CLJS.ci,function(a,b,c){return $CLJS.ME(c)});$CLJS.E0.m(null,$CLJS.bF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.F0.j(a,b,c)});$CLJS.E0.m(null,$CLJS.WI,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.ME(c):$CLJS.F0.j(a,b,d)});
$CLJS.G0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.fC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.dC(l)},e,a,b,c,d)}();
$CLJS.G0.m(null,$CLJS.ci,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Dj,$CLJS.NK,$CLJS.pB,$CLJS.F0.j(a,b,c),$CLJS.T,$CLJS.C0.j(a,b,c),$CLJS.mE,$CLJS.a0.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.gi($CLJS.hE("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([$CLJS.dC(c)])),$CLJS.tV(d)])),new $CLJS.h(null,3,[$CLJS.EF,a,$CLJS.DL,b,$CLJS.zE,c],null),d);}throw e;}});
$CLJS.$_=function(){function a(e,f,k){return $CLJS.G0.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.H0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.fC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.dC(l)},e,a,b,c,d)}();
$CLJS.X(s0,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mE,$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.c0,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OW,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,s0],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[e0,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.f0,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[g0,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.h0,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[u0,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[sna,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[qna,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LG,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Yj],null),
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kt,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.y0,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.w0,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.i0,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.o0,
new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,$CLJS.eG,$CLJS.YF],null)],null)],null));
$CLJS.d0=function(){function a(d,e,f){return $CLJS.Z_($CLJS.zh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.H0.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.gi($CLJS.hE("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.dC(k),$CLJS.tV(l)])),new $CLJS.h(null,3,[$CLJS.EF,d,$CLJS.DL,e,$CLJS.zE,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.H0.m(null,$CLJS.ci,function(a,b,c){return m0(a,b,c)});$CLJS.H0.m(null,$CLJS.UK,function(a,b,c){return $CLJS.fl.l($CLJS.H([m0(a,b,c),new $CLJS.h(null,1,[u0,$CLJS.E.g($CLJS.P_(a),$CLJS.Yi.h(c))],null)]))});
$CLJS.I0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.fC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.dC(l)},e,a,b,c,d)}();$CLJS.I0.m(null,$CLJS.tB,function(){return $CLJS.Cf});
$CLJS.J0=function(){function a(f,k,l,m){m=$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.v0,$CLJS.IW()],null),m]));return $CLJS.I0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.FW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.K0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.fC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.dC(l)},e,a,b,c,d)}();$CLJS.K0.m(null,$CLJS.tB,function(){return $CLJS.Cf});
$CLJS.K0.m(null,$CLJS.ci,function(a,b,c,d){return $CLJS.I0.v(a,b,c,d)});
$CLJS.L0=function(){function a(f,k,l,m){m=$CLJS.fl.l($CLJS.H([$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.v0,$CLJS.IW()],null),new $CLJS.h(null,4,[$CLJS.t0,!0,$CLJS.z0,!0,$CLJS.p0,!0,$CLJS.x0,!0],null)])),m]));return $CLJS.K0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.FW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.a0};