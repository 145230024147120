var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var WW,XW,ZW,$W,nla,bX,cX,VW,dX,ola,pla,qla,rla,hX,iX,kX,lX,mX,nX,oX,pX,tla,ula,rX,vla,sX,wla,uX,xla,yla,zla,Ala,Bla,SW;$CLJS.TW=function(a,b){if("string"===typeof b)return SW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.UW=function(a,b,c){var d=$CLJS.em(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
WW=function(a){var b=VW;return $CLJS.EA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};XW=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Gi,a],null));};
$CLJS.YW=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Qk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};ZW=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
$W=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
nla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Vk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Vk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
bX=function(a,b){for(;;)switch(b=$CLJS.hl.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),aX.h?aX.h(a):aX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve($W,a),b))){var c=a;b=nla(a,b);a=c}else{c=$CLJS.hf.g(aX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};cX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.yA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
VW=function(a){return $CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.CA(ZW(a).toLowerCase(),/_/,"-")):a};dX=function(a,b){var c=cX(b);return $CLJS.n(c)?(b=VW($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ola=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.lj,VW,$CLJS.RN,VW],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=eX.g?eX.g(b,fX):eX.call(null,b,fX);return d.call(c,b,a)})};
pla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=VW(c);var d=ola(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.lj.h(a),$CLJS.BF)&&$CLJS.Va($CLJS.RN.h(a))?$CLJS.R.j(a,$CLJS.RN,$CLJS.AG):a};qla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=ZW(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(pla(b),$CLJS.T,c)],null)}),a)};
rla=function(a){a=eX.h?eX.h(a):eX.call(null,a);return gX.h?gX.h(a):gX.call(null,a)};hX=function(a){return $CLJS.UW($CLJS.UW($CLJS.eb(function(b,c){return $CLJS.UW(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.eD,$CLJS.Lea,$CLJS.EO,sla,$CLJS.Zz,$CLJS.ri],null)),$CLJS.eC,rla),$CLJS.TN,$CLJS.vV)};iX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,null],null):a};kX=function(a){return jX.h(iX(a))};
lX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return aX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(jX,b)))};mX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,kX(b)],null),$CLJS.hf.g(jX,c))};nX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,kX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
oX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,kX(a)],null)};pX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,jX.h(a)],null)};tla=function(a){return $CLJS.Df($CLJS.bm(gX,qX(a)))};ula=function(a){for(;;)if($CLJS.n(cX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Qk.g(iX,a))};
rX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.BF);return $CLJS.n(b)?$CLJS.Rk.j(a,$CLJS.BF,jX):a};
vla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,rX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,rX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};sX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.HQ);return $CLJS.n(b)?$CLJS.Rk.j(a,$CLJS.HQ,vla):a};wla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.jQ);b=$CLJS.Va(a)?tX.h?tX.h(b):tX.call(null,b):b;return $CLJS.n(a)?sX(b):b};uX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
xla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.EF);var c=$CLJS.J.g(b,$CLJS.vN),d=$CLJS.J.g(b,$CLJS.SO),e=$CLJS.J.g(b,$CLJS.jQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.SO);var l=$CLJS.XL($CLJS.Gk.g(f,$CLJS.SO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.SO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Rk.j(l,$CLJS.EF,tX):l,t=$CLJS.n(c)?$CLJS.Rk.j(m,$CLJS.vN,$CLJS.Ve($CLJS.Qk,gX)):m,u=$CLJS.n(e)?$CLJS.Rk.j(t,$CLJS.jQ,sX):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.jQ);return $CLJS.n(x)?$CLJS.R.j(gX($CLJS.Gk.g(v,
$CLJS.jQ)),$CLJS.jQ,x):gX(v)}catch(z){if(z instanceof Error)throw m=z,$CLJS.gi($CLJS.hE("Error canonicalizing query: {0}",$CLJS.H([$CLJS.tV(m)])),new $CLJS.h(null,1,[$CLJS.EF,a],null),m);throw z;}};
yla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.EF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.WF);c=$CLJS.J.g(c,$CLJS.RE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.qC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.hG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,m,$CLJS.Gk.g(t,$CLJS.nG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.AV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.vX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.RE],null),$CLJS.Ok.g($CLJS.Df,$CLJS.Ve($CLJS.Pk,b)))):a};
zla=function(a){try{return yla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.JE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.EF,a],null),b);}throw c;}};
Ala=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=wX.g?wX.g(C,K):wX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return wX.g?wX.g(z,C):wX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};Bla=function(a,b){a=$CLJS.Qk.g(function(c){var d=$CLJS.be.g(b,xX);return wX.g?wX.g(c,d):wX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
SW=function SW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),SW.g?SW.g(a,k):SW.call(null,a,k)):null},null,null))};
$CLJS.vX=function vX(a){switch(arguments.length){case 3:return vX.j(arguments[0],arguments[1],arguments[2]);case 4:return vX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return vX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return vX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.vX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.vX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.vX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.vX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.vX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.vX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.vX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.vX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.vX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.vX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.vX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.vX.A=6;
var aX=function aX(a){for(;;){if($CLJS.xd(a))return $CLJS.At(a,aX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ll(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return bX($CLJS.qs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ss,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return bX($CLJS.xs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return bX($CLJS.xs,t);case "or":return bX($CLJS.qs,t);default:return $CLJS.Qk.g(aX,a)}}else return a}},Cla=new $CLJS.M(null,"value_field","value_field",-980977878),yX=new $CLJS.M(null,"ascending","ascending",-988350486),
zX=new $CLJS.M(null,"named","named",-422393479),AX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),BX=new $CLJS.M(null,"descending","descending",-24766135),CX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),Dla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),DX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),EX=new $CLJS.M(null,"viz-settings","viz-settings",256055379),Ela=new $CLJS.M(null,"rows","rows",850049680),Fla=
new $CLJS.M(null,"special-fn","special-fn",1290649344),xX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),fX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Gla=new $CLJS.M(null,"label_field","label_field",-1573182765),Hla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Ila=new $CLJS.M(null,"joined-field","joined-field",-2048778268),sla=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var FX,Jla=$CLJS.Xe($CLJS.N),Kla=$CLJS.Xe($CLJS.N),Lla=$CLJS.Xe($CLJS.N),Mla=$CLJS.Xe($CLJS.N),Nla=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));FX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Ok.g(VW,$CLJS.A),Nla,Jla,Kla,Lla,Mla);FX.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,b],null);return null!=a?$CLJS.be.g(b,a):b});
FX.m(null,$CLJS.mB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,a instanceof $CLJS.M?ZW(a):a],null)});FX.m(null,$CLJS.CV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(FX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CV,eX.g?eX.g(b,fX):eX.call(null,b,fX),VW(c)],null)});
FX.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=eX.g?eX.g(a,fX):eX.call(null,a,fX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,function(){var d=$CLJS.n($CLJS.pB.h(c))?$CLJS.Rk.j(c,$CLJS.pB,$CLJS.zh):c;d=$CLJS.n($CLJS.nG.h(c))?$CLJS.Rk.j(d,$CLJS.nG,$CLJS.zh):d;return $CLJS.n($CLJS.vR.h(c))?$CLJS.Rk.j(d,$CLJS.vR,function(e){return $CLJS.n($CLJS.CE.h(e))?$CLJS.Rk.j(e,$CLJS.CE,$CLJS.zh):e}):d}()],null)});
FX.m(null,DX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[DX,b instanceof $CLJS.M?ZW(b):b,$CLJS.zh.h(a)],null)});FX.m(null,CX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[CX,eX.g?eX.g(b,fX):eX.call(null,b,fX),$CLJS.ht,VW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[CX,eX.g?eX.g(b,fX):eX.call(null,b,fX),VW(c)],null)});
FX.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(FX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tG,b,c,d],null)),eX.g?eX.g(a,fX):eX.call(null,a,fX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tG,eX.g?eX.g(b,fX):eX.call(null,b,fX),$CLJS.Fd(c)?c:VW(c),VW(d)],null)});
FX.m(null,$CLJS.aJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aJ,b,VW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,$CLJS.Sv],null)});FX.m(null,$CLJS.ZI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZI,b,VW(a)],null)});
FX.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.iJ,eX.g?eX.g(b,fX):eX.call(null,b,fX),c,VW(a)],null)});FX.m(null,$CLJS.oJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.oJ,eX.g?eX.g(b,fX):eX.call(null,b,fX),c,VW(a)],null)});
FX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,eX.g?eX.g(b,fX):eX.call(null,b,fX),VW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,eX.g?eX.g(b,fX):eX.call(null,b,fX)],null)});
FX.m(null,$CLJS.mJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mJ,eX.g?eX.g(b,fX):eX.call(null,b,fX),VW(c),VW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mJ,eX.g?eX.g(b,fX):eX.call(null,b,fX),VW(c)],null)});
FX.m(null,$CLJS.YI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YI,eX.g?eX.g(b,fX):eX.call(null,b,fX),eX.g?eX.g(c,fX):eX.call(null,c,fX),VW(a)],null)});FX.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,b,a],null)});
FX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[VW(a)],null),$CLJS.hf.h(function(c){return eX.g?eX.g(c,fX):eX.call(null,c,fX)}),b)});
var GX=function GX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))){var c=VW(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.Yn,null,$CLJS.Wr,null,$CLJS.wG,null,$CLJS.qG,null,$CLJS.jt,null,$CLJS.iG,null,$CLJS.BG,null,$CLJS.CG,null,$CLJS.NF,null,$CLJS.TF,null,$CLJS.Vr,null,$CLJS.OF,null,$CLJS.vG,null,$CLJS.oG,null,$CLJS.ik,null,$CLJS.Qx,null,$CLJS.bG,null,$CLJS.uG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(cX(a))?(a=$CLJS.A(a),GX.h?GX.h(a):GX.call(null,
a)):null},Ola=new $CLJS.h(null,8,[$CLJS.lj,VW,$CLJS.jQ,function(a){a=WW(a);return $CLJS.y($CLJS.HQ.h(a))?$CLJS.Rk.j(a,$CLJS.HQ,qla):a},$CLJS.EF,new $CLJS.h(null,6,[$CLJS.XF,function HX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a)))return VW(a);if($CLJS.n(dX(zX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[zX,HX.h?HX.h(a):HX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(cX(a))?GX($CLJS.hd(a)):null)?$CLJS.Qk.g(HX,a):eX.g?eX.g(a,
fX):eX.call(null,a,fX)},$CLJS.$P,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[ZW(u),eX.g?eX.g(t,fX):eX.call(null,t,fX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[ZW(l),eX.g?eX.g(f,fX):eX.call(null,f,fX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.PR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(cX(t))?FX.h(t):$CLJS.ce(FX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(cX(l))?FX.h(l):$CLJS.ce(FX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.JQ,function(a){a=WW(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.jQ);if($CLJS.n(b))return a=$CLJS.zV(a,new $CLJS.h(null,1,[$CLJS.jQ,$CLJS.EF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jQ],null),a=eX.g?eX.g(a,b):eX.call(null,a,b),$CLJS.zV(a,new $CLJS.h(null,1,[$CLJS.EF,$CLJS.jQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return eX.g?eX.g(a,b):eX.call(null,a,b)},$CLJS.SO,new $CLJS.h(null,1,[xX,hX],null),$CLJS.EN,new $CLJS.h(null,1,[xX,function(a){a=eX.g?eX.g(a,$CLJS.EF):eX.call(null,a,$CLJS.EF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.CE),d=$CLJS.J.g(b,$CLJS.RE);a=$CLJS.J.g(b,$CLJS.VE);b=$CLJS.n(c)?$CLJS.Rk.j(b,$CLJS.CE,VW):b;d=$CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.Rk.j(b,$CLJS.RE,VW):b;return $CLJS.n(a)?$CLJS.Rk.j(d,$CLJS.VE,ZW):d}],null)],null),$CLJS.Fy,
new $CLJS.h(null,1,[$CLJS.pO,$CLJS.Td],null),$CLJS.vN,new $CLJS.h(null,1,[xX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.lj),d=$CLJS.J.g(b,$CLJS.tJ),e=$CLJS.J.g(b,$CLJS.Yi);a=$CLJS.J.g(b,AX);b=$CLJS.n(e)?$CLJS.Rk.j(b,$CLJS.Yi,ZW):b;c=$CLJS.n(c)?$CLJS.Rk.j(b,$CLJS.lj,VW):b;d=$CLJS.n(d)?$CLJS.Rk.j(c,$CLJS.tJ,function(f){return eX.g?eX.g(f,fX):eX.call(null,f,fX)}):c;d=$CLJS.n(a)?$CLJS.vX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[AX,Gla],null),function(f){return eX.g?eX.g(f,fX):eX.call(null,f,fX)}):d;return $CLJS.n(a)?
$CLJS.vX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[AX,Cla],null),function(f){return eX.g?eX.g(f,fX):eX.call(null,f,fX)}):d}],null),$CLJS.qN,function(a){return null==a?null:VW(a)},$CLJS.SO,new $CLJS.h(null,1,[xX,hX],null),EX,VW],null),eX=function eX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return eX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
eX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.zA(Ola,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=VW(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,eX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=VW(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,eX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(cX(a))?FX.h(a):$CLJS.wd(a)?$CLJS.Qk.g(function(e){return eX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),xX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.hE("Error normalizing form: {0}",$CLJS.H([$CLJS.tV(d)])),new $CLJS.h(null,3,[$CLJS.Xz,a,$CLJS.nl,c,Fla,b],null),d);}throw e;}};eX.A=1;eX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var jX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(cX(f))?$CLJS.A(f):null},e,a,b,c,d)}();jX.m(null,$CLJS.ci,function(a){return a});
jX.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(dX($CLJS.hG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return jX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,c,$CLJS.Fe($CLJS.fl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,$CLJS.Fe(a)],null)});
jX.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,b,a],null)});jX.m(null,$CLJS.WK,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(cX(a))?jX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,null],null)});
jX.m(null,DX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,new $CLJS.h(null,1,[$CLJS.pB,a],null)],null)});jX.m(null,Hla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=kX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=kX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,$CLJS.R.j(c,$CLJS.FN,b)],null)});
jX.m(null,Ila,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=kX(a);return $CLJS.Se.v($CLJS.HV,a,$CLJS.R,$CLJS.H([$CLJS.uP,b]))});
jX.m(null,CX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=kX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.pB);$CLJS.Va(d)||$CLJS.MS.g(d,b)?a=$CLJS.Se.v($CLJS.HV,a,$CLJS.R,$CLJS.H([$CLJS.nG,b])):(c=$CLJS.IA($CLJS.Dy),$CLJS.n($CLJS.HA("metabase.mbql.util",c))&&(b=$CLJS.hE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.GA("metabase.mbql.util",c,$CLJS.zw(),b):$CLJS.GA("metabase.mbql.util",c,$CLJS.zw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),jX.h(new $CLJS.P(null,3,5,$CLJS.Q,[CX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
jX.m(null,$CLJS.CV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=kX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,$CLJS.R.j(e,$CLJS.vR,$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.CE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var IX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.qs,$CLJS.ss],null)),JX=null,KX=0,LX=0;;)if(LX<KX){var MX=JX.X(null,LX);jX.m(null,MX,function(){return function(a){return lX(a)}}(IX,JX,KX,LX,MX));LX+=1}else{var NX=$CLJS.y(IX);if(NX){var OX=NX;if($CLJS.Ad(OX)){var PX=$CLJS.lc(OX),Pla=$CLJS.mc(OX),Qla=PX,Rla=$CLJS.D(PX);IX=Pla;JX=Qla;KX=Rla}else{var QX=$CLJS.A(OX);jX.m(null,QX,function(){return function(a){return lX(a)}}(IX,JX,KX,LX,QX,OX,NX));IX=$CLJS.B(OX);JX=null;KX=0}LX=0}else break}
jX.m(null,$CLJS.QF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,kX(a),kX(b)],null),c)});jX.m(null,$CLJS.tG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=kX(a);a=$CLJS.n($W($CLJS.hG,a))?$CLJS.HV.l(c,$CLJS.Gk,$CLJS.H([$CLJS.nG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,a],null),b)});
for(var RX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ZF,$CLJS.sG,$CLJS.gG,$CLJS.$F,$CLJS.Tr,$CLJS.yG,$CLJS.Qr,$CLJS.Sr,$CLJS.Mr,$CLJS.Or,$CLJS.LF,$CLJS.SF,$CLJS.JF,$CLJS.UF,$CLJS.KF],null)),SX=null,TX=0,UX=0;;)if(UX<TX){var VX=SX.X(null,UX);jX.m(null,VX,function(){return function(a){return mX(a)}}(RX,SX,TX,UX,VX));UX+=1}else{var WX=$CLJS.y(RX);if(WX){var XX=WX;if($CLJS.Ad(XX)){var YX=$CLJS.lc(XX),Sla=$CLJS.mc(XX),Tla=YX,Ula=$CLJS.D(YX);RX=Sla;SX=Tla;TX=Ula}else{var ZX=$CLJS.A(XX);jX.m(null,ZX,
function(){return function(a){return mX(a)}}(RX,SX,TX,UX,ZX,XX,WX));RX=$CLJS.B(XX);SX=null;TX=0}UX=0}else break}jX.m(null,Ela,function(){return null});jX.m(null,$CLJS.vM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vM,jX.h(b),a],null)});
jX.m(null,zX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=jX.h;var e=$CLJS.Q;b=jX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,Dla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.mE,a],null);return c.call(jX,new $CLJS.P(null,3,5,e,[$CLJS.vM,b,a],null))});
for(var $X=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qx,$CLJS.iG],null)),aY=null,bY=0,cY=0;;)if(cY<bY){var dY=aY.X(null,cY);jX.m(null,dY,function(){return function(a){return nX(a)}}($X,aY,bY,cY,dY));cY+=1}else{var eY=$CLJS.y($X);if(eY){var fY=eY;if($CLJS.Ad(fY)){var gY=$CLJS.lc(fY),Vla=$CLJS.mc(fY),Wla=gY,Xla=$CLJS.D(gY);$X=Vla;aY=Wla;bY=Xla}else{var hY=$CLJS.A(fY);jX.m(null,hY,function(){return function(a){return nX(a)}}($X,aY,bY,cY,hY,fY,eY));$X=$CLJS.B(fY);aY=null;bY=0}cY=0}else break}
for(var iY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bG,$CLJS.DG,$CLJS.NF,$CLJS.wG,$CLJS.uG,$CLJS.Yn,$CLJS.ik,$CLJS.vG,$CLJS.OF],null)),jY=null,kY=0,lY=0;;)if(lY<kY){var mY=jY.X(null,lY);jX.m(null,mY,function(){return function(a){return oX(a)}}(iY,jY,kY,lY,mY));lY+=1}else{var nY=$CLJS.y(iY);if(nY){var oY=nY;if($CLJS.Ad(oY)){var pY=$CLJS.lc(oY),Yla=$CLJS.mc(oY),Zla=pY,$la=$CLJS.D(pY);iY=Yla;jY=Zla;kY=$la}else{var qY=$CLJS.A(oY);jX.m(null,qY,function(){return function(a){return oX(a)}}(iY,jY,kY,lY,
qY,oY,nY));iY=$CLJS.B(oY);jY=null;kY=0}lY=0}else break}jX.m(null,$CLJS.TF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,kX(b),a],null)});
for(var rY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,$CLJS.qG],null)),sY=null,tY=0,uY=0;;)if(uY<tY){var vY=sY.X(null,uY);jX.m(null,vY,function(){return function(a){return pX(a)}}(rY,sY,tY,uY,vY));uY+=1}else{var wY=$CLJS.y(rY);if(wY){var xY=wY;if($CLJS.Ad(xY)){var yY=$CLJS.lc(xY),ama=$CLJS.mc(xY),bma=yY,cma=$CLJS.D(yY);rY=ama;sY=bma;tY=cma}else{var zY=$CLJS.A(xY);jX.m(null,zY,function(){return function(a){return pX(a)}}(rY,sY,tY,uY,zY,xY,wY));rY=$CLJS.B(xY);sY=null;tY=0}uY=0}else break}
jX.m(null,$CLJS.BG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,jX.h(b),jX.h(a)],null)});
jX.m(null,$CLJS.RF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(jX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,b],null)),eX.l(a,$CLJS.H([fX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[jX.h(v),jX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[jX.h(m),jX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
jX.m(null,$CLJS.AF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,jX.h(a),$CLJS.E.g(0,b)?1:jX.h(b)],null),$CLJS.hf.g(jX,c))});
var gX=function gX(a){if($CLJS.yd(a))return $CLJS.Sd(function(d,e,f){return $CLJS.R.j(d,e,gX.h?gX.h(f):gX.call(null,f))},a,a);if($CLJS.xd(a))return $CLJS.At(a,gX);if($CLJS.n(cX(a))){var c=function(){try{return jX.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.IA($CLJS.By);if($CLJS.n($CLJS.HA("metabase.mbql.normalize",e))){var f=$CLJS.JE("Invalid clause:");f instanceof Error?$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([a])),f):$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([f,
a])),null)}throw $CLJS.gi($CLJS.hE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.tV(d)])),new $CLJS.h(null,1,[$CLJS.aS,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.eg.j($CLJS.be.g($CLJS.jd(c),$CLJS.A(c)),$CLJS.hf.h(gX),$CLJS.Lc(c)):c}return $CLJS.Dd(a)?$CLJS.Qk.g(gX,a):$CLJS.ud(a)?$CLJS.eg.j($CLJS.jd(a),$CLJS.hf.h(gX),a):a},qX=function qX(a){return function f(d,e){try{if($CLJS.n(function(){var x=XW($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=XW($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Vk.j(e,0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.fE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.Wr,null,$CLJS.jt,null,$CLJS.CG,null,$CLJS.Vr,null,
zX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(GX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.gE(qX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.Vk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;
}else throw d;else throw x;}}($CLJS.Cf,a)},AY=function AY(a){return function f(d,e){try{var k=XW($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.eG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.YF))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,yX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,BX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===
$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,yX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,BX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.eG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,kX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof
Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.YF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,kX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.Vk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Vk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.hl.h($CLJS.hf.g(AY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.YW(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.Cf,a)},tX=$CLJS.Ok.g(gX,function(a){var b=uX($CLJS.XF.h(a))?$CLJS.Rk.j(a,$CLJS.XF,tla):a;b=uX($CLJS.WF.h(a))?$CLJS.Rk.j(b,$CLJS.WF,ula):b;b=uX($CLJS.RE.h(a))?$CLJS.Rk.j(b,$CLJS.RE,$CLJS.Ve($CLJS.Qk,iX)):b;b=uX($CLJS.PR.h(a))?$CLJS.Rk.j(b,$CLJS.PR,AY):b;return uX($CLJS.JQ.h(a))?$CLJS.Rk.j(b,$CLJS.JQ,wla):b}),dma=new $CLJS.h(null,3,[$CLJS.jQ,$CLJS.Td,$CLJS.EF,new $CLJS.h(null,2,[$CLJS.JQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.jQ);if($CLJS.n(b))return a=
$CLJS.zV(a,new $CLJS.h(null,1,[$CLJS.jQ,$CLJS.EF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jQ],null),a=wX.g?wX.g(a,b):wX.call(null,a,b),$CLJS.zV(a,new $CLJS.h(null,1,[$CLJS.EF,$CLJS.jQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return wX.g?wX.g(a,b):wX.call(null,a,b)},$CLJS.EN,new $CLJS.h(null,1,[xX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return wX.g?wX.g(a,b):wX.call(null,a,b)}],null)],null),EX,$CLJS.Td],null),wX=function wX(a){switch(arguments.length){case 1:return wX.h(arguments[0]);
case 2:return wX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};wX.h=function(a){return wX.g(a,$CLJS.Cf)};wX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.zA(dma,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?Ala(a,b):$CLJS.wd(a)?Bla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Xz,a,$CLJS.nl,b],null),d);throw d;}};wX.A=2;
$CLJS.BY=function(){var a=$CLJS.Ok.l(wX,zla,xla,$CLJS.H([eX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.hE("Error normalizing query: {0}",$CLJS.H([$CLJS.tV(c)])),new $CLJS.h(null,1,[$CLJS.EF,b],null),c);}throw d;}}}();$CLJS.CY=function CY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.BY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.yt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=CY.g?CY.g(f,b):CY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};