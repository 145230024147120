var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var AL,CL,EL,HL,ML;$CLJS.wL=function(a){return $CLJS.Wh($CLJS.q($CLJS.fC),a,$CLJS.lG)};$CLJS.xL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.yL=new $CLJS.M(null,"row","row",-570139521);$CLJS.zL=new $CLJS.M(null,"object-id","object-id",-754527291);AL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.BL=new $CLJS.M(null,"operators","operators",-2064102509);
CL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.DL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);EL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.FL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.GL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
HL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.IL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.JL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.KL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.LL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
ML=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.NL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.OL=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);$CLJS.X(CL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,$CLJS.wE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nG,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.dH],null)],null)],null));$CLJS.X(ML,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,CL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.AE],null)],null)],null));$CLJS.X(EL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tr,$CLJS.hG],null),ML,$CLJS.rE],null));
$CLJS.X(HL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tr,$CLJS.hG],null),CL,$CLJS.fL],null));
$CLJS.HG.g($CLJS.hG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tr,$CLJS.hG],null),CL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.fL,$CLJS.rE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.Ni,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.dC(a)):null},$CLJS.qt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.hB,HL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gB,EL],null)],null)],null));$CLJS.pF($CLJS.hG,$CLJS.lG);$CLJS.LE.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.YE});$CLJS.wF($CLJS.mB,$CLJS.H([$CLJS.rE]));$CLJS.LE.m(null,$CLJS.mB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.YE});
$CLJS.pF($CLJS.mB,$CLJS.lG);$CLJS.X(AL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,$CLJS.wE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.rE],null)],null)],null));$CLJS.HG.g($CLJS.XF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tr,$CLJS.XF],null),AL,$CLJS.Yj],null));
$CLJS.LE.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.YE});$CLJS.pF($CLJS.XF,$CLJS.lG);$CLJS.wF($CLJS.GF,$CLJS.H([$CLJS.jt,$CLJS.Kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QK],null)],null)]));$CLJS.pF($CLJS.GF,$CLJS.lG);
$CLJS.wF($CLJS.cG,$CLJS.H([$CLJS.jt,$CLJS.YE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ol,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OK],null)],null)]));$CLJS.pF($CLJS.cG,$CLJS.lG);
$CLJS.X($CLJS.lG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.NE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.st,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Bt(", ",$CLJS.TG($CLJS.Jd,$CLJS.jl.g($CLJS.q($CLJS.fC),$CLJS.lG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.wL(a)}],null)],null));