var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var sW,gla,hla,AW,ila,jla,lla,wW,mla,kla;sW=function(a){return a+1};$CLJS.tW=function(a){if("string"===typeof a){var b=$CLJS.nh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.im(a));};
gla=function(a){var b=new $CLJS.Ea,c=$CLJS.wu;$CLJS.wu=new $CLJS.sc(b);try{var d=$CLJS.wu,e=$CLJS.Va($CLJS.Ev(d)),f=$CLJS.wu;$CLJS.wu=e?$CLJS.vv(d):d;try{d=$CLJS.Gv;$CLJS.Gv=!0;try{$CLJS.Iv(a)}finally{$CLJS.Gv=d}$CLJS.E.g(0,$CLJS.Du($CLJS.wu,$CLJS.Fu))||$CLJS.ac($CLJS.wu,"\n");$CLJS.Cu()}finally{$CLJS.wu=f}$CLJS.uh($CLJS.p.h(b))}finally{$CLJS.wu=c}};$CLJS.uW=function(a,b){return $CLJS.Se.j($CLJS.fa,a,b)};
$CLJS.vW=function(a){var b=$CLJS.sv;$CLJS.sv=120;try{var c=new $CLJS.Ea,d=$CLJS.Qa,e=$CLJS.Ra;$CLJS.Qa=!0;$CLJS.Ra=function(f){return c.append(f)};try{gla(a)}finally{$CLJS.Ra=e,$CLJS.Qa=d}return $CLJS.p.h(c)}finally{$CLJS.sv=b}};
hla=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.EV,$CLJS.Td),c=$CLJS.J.j(a,$CLJS.BV,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Xe($CLJS.N),e=$CLJS.Xe($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var z=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(z):C.call(null,z)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Oh.v(e,$CLJS.Rk,u,$CLJS.TD(sW,0)),u);if($CLJS.E.g(x,1))return $CLJS.Oh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var z=t,C=$CLJS.p.h(x);return c.g?c.g(z,C):c.call(null,z,C)}()}}function k(m){var t=l,u=t.g;null==wW&&(wW=$CLJS.Xe(0));var v=$CLJS.Fh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Oh.g(wW,sW))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.xW=function(a){return $CLJS.zd(a)&&1<$CLJS.D(a)&&$CLJS.A(a)instanceof $CLJS.M&&$CLJS.xd($CLJS.hd(a))&&$CLJS.Id($CLJS.hd(a),$CLJS.yE)};$CLJS.yW=function(a){return $CLJS.xW(a)?$CLJS.qE.h($CLJS.FE(a)):null};$CLJS.zW=function(a,b){return $CLJS.GE($CLJS.xW(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,2,[$CLJS.yE,$CLJS.p.h($CLJS.IE()),$CLJS.Ei,$CLJS.ME(a)],null),a],null),$CLJS.R,$CLJS.H([$CLJS.qE,b]))};
AW=function(a,b,c){var d=$CLJS.Id(a,b)?$CLJS.Rk.j(a,b,function(e){return $CLJS.zd(e)&&$CLJS.E.g($CLJS.A(e),$CLJS.xs)?$CLJS.Df($CLJS.bf(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Id(a,b)?$CLJS.zV(d,$CLJS.Ee([b,c])):d};ila=function(a){var b=$CLJS.dm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fP,$CLJS.JQ],null));b=BW.h?BW.h(b):BW.call(null,b);return $CLJS.pW($CLJS.R.l(AW($CLJS.Gk.l(a,$CLJS.fP,$CLJS.H([$CLJS.JQ])),$CLJS.RR,$CLJS.rL),$CLJS.Dj,$CLJS.jL,$CLJS.H([$CLJS.oL,b])))};
jla=function(a){return $CLJS.Qk.g(ila,a)};lla=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Rk.j($CLJS.Ll(a)?new $CLJS.h(null,1,[$CLJS.CW,a],null):a,$CLJS.CW,function(b){return $CLJS.Qk.g(function(c){return $CLJS.R.j($CLJS.AA(c,$CLJS.aB),$CLJS.Dj,$CLJS.NK)},b)}),$CLJS.Dj,kla):null};
$CLJS.DW=function(a){if($CLJS.E.g($CLJS.Dj.h(a),$CLJS.hW))return a;var b=$CLJS.lj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.fl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Dj,$CLJS.hW,$CLJS.oL,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Dj,$CLJS.bW],null),$CLJS.zV($CLJS.jQ.h(a),new $CLJS.h(null,1,[$CLJS.EF,$CLJS.jQ],null))]))],null)],null),$CLJS.Gk.l(a,$CLJS.lj,$CLJS.H([$CLJS.jQ]))]));case "query":return $CLJS.fl.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.Dj,$CLJS.hW,$CLJS.oL,BW($CLJS.EF.h(a))],null),$CLJS.Gk.l(a,$CLJS.lj,$CLJS.H([$CLJS.EF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.EW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.oL);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.hi($CLJS.hE("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[mla,$CLJS.D(a)],null));return c};
$CLJS.FW=function(a,b){a=$CLJS.DW(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.oL);return $CLJS.J.g($CLJS.Df(c),$CLJS.EW(a,b))};$CLJS.GW=function(a){return"string"===typeof a&&(a=$CLJS.oh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.tW(a)):null};$CLJS.IW=function(){return $CLJS.Ok.g(HW,hla($CLJS.H([$CLJS.EV,$CLJS.XA,$CLJS.BV,function(a,b){return HW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};wW=null;
$CLJS.JW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.KW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.LW=new $CLJS.M(null,"join","join",-758861890);$CLJS.MW=new $CLJS.M(null,"stage","stage",1843544772);mla=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.CW=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.NW=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.OW=new $CLJS.M(null,"table","table",-564943036);kla=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.PW=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var BW,HW;
BW=function BW(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.JQ),d=$CLJS.J.g(a,$CLJS.SO);c=$CLJS.n(c)?BW.h?BW.h(c):BW.call(null,c):$CLJS.Cf;d=lla(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.XL(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.oV],null),d):c;d=$CLJS.n($CLJS.jQ.h(a))?$CLJS.bW:$CLJS.VV;a=$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Dj,d],null),$CLJS.Gk.l(a,$CLJS.JQ,$CLJS.H([$CLJS.SO]))]));a=$CLJS.y($CLJS.EN.h(a))?$CLJS.Rk.j(a,$CLJS.EN,jla):a;a=AW(a,$CLJS.MF,$CLJS.iW);return $CLJS.be.g(c,a)};
$CLJS.QW=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.DW(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.oL);d=$CLJS.EW(c,d);e=$CLJS.Se.N($CLJS.Rk,$CLJS.Df(k),d,e,f);return $CLJS.R.j(c,$CLJS.oL,e)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
HW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.nW.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.RW=RegExp(" id$","i");