var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var a1,Ona,b1,c1;$CLJS.Z0=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};a1=function(a){return $CLJS.n($0)?["(",$CLJS.p.h(a),")"].join(""):a};Ona=function(a,b){var c=$CLJS.Rk.v(a,$CLJS.$P,$CLJS.TD($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.RE.h(a))?$CLJS.Rk.v(c,$CLJS.RE,$CLJS.be,$CLJS.pW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mB,$CLJS.N,$CLJS.yW(b)],null))):c};
b1=function(a,b,c){var d=$CLJS.yW(c);return $CLJS.R.l($CLJS.$_.j(a,b,c),$CLJS.PK,$CLJS.EZ,$CLJS.H([$CLJS.T,d,$CLJS.mE,d]))};c1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.d1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var e1=function(){function a(d,e,f){var k=$CLJS.FW(d,e);k=$CLJS.uH($CLJS.Ok.g($CLJS.ch([f]),$CLJS.yW),$CLJS.$P.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.hE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.bS,f,$CLJS.EF,d,$CLJS.DL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.E0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=e1.j(a,b,c);return $CLJS.F0.j(a,b,c)});$CLJS.G0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Dj,$CLJS.NK,$CLJS.d1,$CLJS.yE.h(d),$CLJS.T,e,$CLJS.qE,e,$CLJS.mE,$CLJS.a0.j(a,b,c),$CLJS.pB,$CLJS.F0.j(a,b,c),$CLJS.PK,$CLJS.EZ],null)});$CLJS.A0.m(null,$CLJS.hB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.A0.m(null,$CLJS.fB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.A0.m(null,$CLJS.gB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.A0.m(null,$CLJS.xB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.A0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.B0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var $0=!1,Pna=new $CLJS.h(null,4,[$CLJS.Vr,"+",$CLJS.jt,"-",$CLJS.Wr,"×",$CLJS.CG,"÷"],null),f1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Vr,$CLJS.jt,$CLJS.CG,$CLJS.Wr],null)),g1=null,h1=0,i1=0;;)if(i1<h1){var Qna=g1.X(null,i1);$CLJS.pF(Qna,c1);i1+=1}else{var j1=$CLJS.y(f1);if(j1){var k1=j1;if($CLJS.Ad(k1)){var l1=$CLJS.lc(k1),Rna=$CLJS.mc(k1),Sna=l1,Tna=$CLJS.D(l1);f1=Rna;g1=Sna;h1=Tna}else{var Una=$CLJS.A(k1);$CLJS.pF(Una,c1);f1=$CLJS.B(k1);g1=null;h1=0}i1=0}else break}
$CLJS.A0.m(null,c1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Pna,c);a:{var e=$0;$0=!0;try{var f=$CLJS.Bt([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.$t($CLJS.a0,a,b),d));break a}finally{$0=e}f=void 0}return a1(f)});$CLJS.B0.m(null,c1,function(){return"expression"});
$CLJS.E0.m(null,$CLJS.BH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.rF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.F0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.F0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.A0.m(null,$CLJS.iJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.a0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.T0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.xA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=a1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.B0.m(null,$CLJS.iJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.C0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.T0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.xA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.A0.m(null,$CLJS.kG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.a0.v(a,b,e,d)});$CLJS.B0.m(null,$CLJS.kG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.C0.j(a,b,d)});
$CLJS.Vna=function(){function a(d,e,f,k){return $CLJS.QW.l(d,$CLJS.n(e)?e:-1,Ona,$CLJS.H([$CLJS.zW($CLJS.rW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Wna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.$P.h($CLJS.FW(d,e)));return null==f?null:$CLJS.Qk.g($CLJS.$t(b1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.m1=function(){function a(d,e){return $CLJS.Fe($CLJS.$P.h($CLJS.FW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.oW.m(null,$CLJS.mB,function(a){return a});
$CLJS.Xna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.cm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yW(t),m],null)},$CLJS.m1.g(d,e))),l=$CLJS.FW(d,e);d=$CLJS.L0.j(d,e,l);return $CLJS.Fe($CLJS.Z0(function(m){return $CLJS.Va(f)||$CLJS.Mk.g($CLJS.PK.h(m),$CLJS.EZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Yna=function(){function a(d,e,f){return $CLJS.qW(b1(d,e,e1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();